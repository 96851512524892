import React, { FC } from "react";

import { ItsoundsSpeaker1 } from "../../assets/images/Images";
import Layout from "../../components/Layout";
import Introduction, {
  ItSoundsSpeakerImage
} from "../../partials/general/Introduction";
import SuccessStoriesFooter from "../../partials/general/SuccessStoriesFooter";
import AboutSection from "../../partials/ItSounds/AboutSection";
import BrandSection from "../../partials/ItSounds/BrandSection";
import SolutionSection from "../../partials/ItSounds/SolutionSection";

const Itsound: FC = () => {
  const ID = "itsounds";

  return (
    <Layout
      helmetKey={ID}
      emptyMenu={true}
      blackMenu={true}
      lightColorVariant
      menuIconType="/success-stories"
      render={(): React.ReactChild => (
        <>
          <Introduction
            caseStudyId={ID}
            picture={
              <ItSoundsSpeakerImage
                src={ItsoundsSpeaker1}
                alt={"Itsounds Speaker"}
              />
            }
          />
          <AboutSection />
          <SolutionSection />
          <BrandSection />
          <SuccessStoriesFooter />
        </>
      )}
    />
  );
};

export default Itsound;
