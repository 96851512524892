import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import { ItsoundsSpeaker2 } from "../../assets/images/Images";
import Container from "../../components/Container";
import { Block, GridWrapper, Image } from "../../components/GridComponents";
import Heading from "../../components/Heading";
import Paragraph from "../../components/Paragraph";
import Spacer from "../../components/Spacer";
import { useResize } from "../../hooks/useResize";
import { t } from "../../intl/getTranslation";
import styled from "../../styling/styled";
import { breakpoints, rem, theme } from "../../styling/theme";
import { SIDEBAR_WIDTH } from "../SideBar";

const Wrapper = styled.div`
  background-color: ${({ theme }): string => theme.colors.black};
  color: ${({ theme }): string => theme.colors.white};
  padding-left: ${rem(SIDEBAR_WIDTH)};
  margin-left: ${rem(-SIDEBAR_WIDTH)};
`;

const Screen = styled(Image)`
  width: ${rem(314)};
  align-self: center;
`;

const SolutionSection: FC = () => {
  const intl = useIntl();
  const { windowWidth } = useResize();

  return (
    <Wrapper>
      <Container>
        <GridWrapper>
          <Block
            width={windowWidth < breakpoints.md ? 12 : 6}
            justifyContent="center"
          >
            <Heading
              title={t("itsounds.solution_section.title", intl)}
              symbolColor={theme.colors.white}
            />
            <Paragraph
              paragraph={t("itsounds.solution_section.paragraph1", intl)}
              maxWidth={450}
              color={theme.colors.white}
            />
            <Paragraph
              paragraph={t("itsounds.solution_section.paragraph2", intl)}
              maxWidth={450}
              color={theme.colors.white}
            />
            <Spacer size="large" />
          </Block>
          <Block width={6}>
            <Screen src={ItsoundsSpeaker2} alt="itSOUNDS" />
          </Block>
        </GridWrapper>
      </Container>
    </Wrapper>
  );
};

export default SolutionSection;
